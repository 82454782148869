import type { UserToken } from '@/interfaces/UserToken'
import { createRouter, createWebHistory } from '@ionic/vue-router'
import type { RouteRecordRaw } from 'vue-router'
import { getUserTokens } from '../modules/LocalStorage'
import BaseView from '../views/BaseView.vue'

const routes: RouteRecordRaw[] = [
  {
    path: '',
    redirect: '/login'
  },
  {
    name: 'login',
    path: '/login',
    component: async () => await import ('../views/Login.vue')
  },
  {
    path: '/',
    // redirect: '/employee/mark-work-time',
    component: BaseView,
    children: [
      {
        name: 'mark-work-time',
        path: '/employee/mark-work-time',
        component: async () => await import ('../views/Employee/MarkWorkTime.vue')
      },
      {
        name: 'work-time-history',
        path: '/employee/work-time-history',
        component: async () => await import ('../views/Employee/WorkTimeHistory/WorkTimeHistory.vue')
      },
      {
        name: 'create-work-time-history',
        path: '/employee/create-work-time-history',
        component: async () => await import ('../views/Employee/WorkTimeHistory/CreateWorkTimeHistory.vue')
      },
      {
        name: 'edit-work-time-history',
        path: '/employee/edit-work-time-history/:id',
        component: async () => await import ('../views/Employee/WorkTimeHistory/EditWorkTimeHistory.vue')
      },
      {
        name: 'edit-user',
        path: '/employee/edit-user',
        component: async () => await import ('../views/Employee/EditProfile.vue')
      },
      {
        name: 'diary',
        path: '/employee/diary',
        component: async () => await import ('../views/Employee/Diary/ViewDiary.vue')
      },
      {
        name: 'edit-diary',
        path: '/employee/edit-diary/:unit_id/:diary_id',
        component: async () => await import ('../views/Employee/Diary/EditDiary.vue')
      },
      {
        name: 'request',
        path: '/employee/request',
        component: async () => await import ('../views/Employee/Request/Request.vue')
      },
      {
        name: 'create-request',
        path: '/employee/create-request',
        component: async () => await import ('../views/Employee/Request/CreateRequest.vue')
      },
      {
        name: 'edit-request',
        path: '/employee/edit-request/:id',
        component: async () => await import ('../views/Employee/Request/EditRequest.vue')
      },
      {
        name: 'vehicle-sheet',
        path: '/employee/vehicle-sheet',
        component: async () => await import ('../views/Employee/Vehicle/VehicleSheet.vue')
      },
      {
        name: 'create-vehicle-sheet',
        path: '/employee/create-vehicle-sheet',
        component: async () => await import ('../views/Employee/Vehicle/CreateVehicleSheet.vue')
      },
      {
        name: 'edit-vehicle-sheet',
        path: '/employee/edit-vehicle-sheet/:id',
        component: async () => await import ('../views/Employee/Vehicle/EditVehicleSheet.vue')
      },
      {
        name: 'protected-meeting',
        path: '/employee/protected-meeting',
        component: async () => await import ('../views/Employee/ProtectedMeeting/ProtectedMeeting.vue')
      },
      {
        name: 'view-protected-meeting',
        path: '/employee/view-protected-meeting/:id',
        component: async () => await import ('../views/Employee/ProtectedMeeting/ViewProtectedMeeting.vue')
      },
      {
        name: 'create-protected-meeting',
        path: '/employee/create-protected-meeting',
        component: async () => await import ('../views/Employee/ProtectedMeeting/CreateProtectedMeeting.vue')
      },
      {
        name: 'edit-protected-meeting',
        path: '/employee/edit-protected-meeting/:id',
        component: async () => await import ('../views/Employee/ProtectedMeeting/EditProtectedMeeting.vue')
      },
      {
        name: 'commission',
        path: '/employee/commission',
        component: async () => await import ('../views/Employee/Commission/Commission.vue')
      },
      {
        name: 'create-commission',
        path: '/employee/create-commission',
        component: async () => await import ('../views/Employee/Commission/CreateCommission.vue')
      },
      {
        name: 'edit-commission',
        path: '/employee/edit-commission/:id',
        component: async () => await import ('../views/Employee/Commission/EditCommission.vue')
      },
      {
        name: 'files',
        path: '/employee/files',
        component: async () => await import ('../views/Employee/File/FileList.vue')
      },
      {
        name: 'admin-work-time-history',
        path: '/admin/admin-work-time-history',
        component: async () => await import ('../views/Admin/WorkTimeHistory/WorkTimeHistory.vue')
      },
      {
        name: 'admin-create-work-time-history',
        path: '/admin/admin-create-work-time-history',
        component: async () => await import ('../views/Admin/WorkTimeHistory/CreateWorkTimeHistory.vue')
      },
      {
        name: 'admin-edit-work-time-history',
        path: '/admin/admin-edit-work-time-history/:id',
        component: async () => await import ('../views/Admin/WorkTimeHistory/EditWorkTimeHistory.vue')
      },
      {
        name: 'admin-diary',
        path: '/admin/diary',
        component: async () => await import ('../views/Admin/Diary/ViewDiary.vue')
      },
      {
        name: 'admin-edit-diary',
        path: '/admin/edit-diary',
        component: async () => await import ('../views/Admin/Diary/EditDiary.vue')
      },
      {
        name: 'admin-users',
        path: '/admin/users',
        component: async () => await import ('../views/Admin/User/UserManagement.vue')
      },
      {
        name: 'admin-create-user',
        path: '/admin/users/create',
        component: async () => await import ('../views/Admin/User/CreateUser.vue')
      },
      {
        name: 'admin-edit-user',
        path: '/admin/users/edit/:id',
        component: async () => await import ('../views/Admin/User/EditUser.vue')
      },
      {
        name: 'admin-roles',
        path: '/admin/roles',
        component: async () => await import ('../views/Admin/Role/RoleList.vue')
      },
      {
        name: 'admin-create-role',
        path: '/admin/roles/create',
        component: async () => await import ('../views/Admin/Role/CreateRole.vue')
      },
      {
        name: 'admin-edit-role',
        path: '/admin/roles/edit/:id',
        component: async () => await import ('../views/Admin/Role/EditRole.vue')
      },
      {
        name: 'admin-edit-coop',
        path: '/admin/coop/edit/:id?',
        component: async () => await import ('../views/Admin/Coop/EditCoop.vue')
      },
      {
        name: 'admin-units',
        path: '/admin/units',
        component: async () => await import ('../views/Admin/Unit/UnitList.vue')
      },
      {
        name: 'admin-create-unit',
        path: '/admin/units/create',
        component: async () => await import ('../views/Admin/Unit/CreateUnit.vue')
      },
      {
        name: 'admin-edit-unit',
        path: '/admin/units/edit/:id',
        component: async () => await import ('../views/Admin/Unit/EditUnit.vue')
      },
      {
        name: 'admin-create-subunit',
        path: '/admin/subunits/create',
        component: async () => await import ('../views/Admin/Unit/CreateSubUnit.vue')
      },
      {
        name: 'admin-edit-subunit',
        path: '/admin/subunits/edit/:id',
        component: async () => await import ('../views/Admin/Unit/EditSubUnit.vue')
      },
      {
        name: 'admin-export',
        path: '/admin/export',
        component: async () => await import ('../views/Admin/Export/ExportWorkTime.vue')
      },
      {
        name: 'admin-request',
        path: '/admin/request',
        component: async () => await import ('../views/Admin/Request/Request.vue')
      },
      {
        name: 'admin-create-request',
        path: '/admin/create-request',
        component: async () => await import ('../views/Admin/Request/CreateRequest.vue')
      },
      {
        name: 'admin-edit-request',
        path: '/admin/edit-request/:id',
        component: async () => await import ('../views/Admin/Request/EditRequest.vue')
      },
      {
        name: 'admin-vehicle-sheet',
        path: '/admin/vehicle-sheet',
        component: async () => await import ('../views/Admin/VehicleSheet/VehicleSheet.vue')
      },
      {
        name: 'admin-create-vehicle-sheet',
        path: '/admin/create-vehicle-sheet',
        component: async () => await import ('../views/Admin/VehicleSheet/CreateVehicleSheet.vue')
      },
      {
        name: 'admin-edit-vehicle-sheet',
        path: '/admin/edit-vehicle-sheet/:id',
        component: async () => await import ('../views/Admin/VehicleSheet/EditVehicleSheet.vue')
      },
      {
        name: 'admin-vehicle',
        path: '/admin/vehicle',
        component: async () => await import ('../views/Admin/Vehicle/Vehicle.vue')
      },
      {
        name: 'admin-create-vehicle',
        path: '/admin/create-vehicle',
        component: async () => await import ('../views/Admin/Vehicle/CreateVehicle.vue')
      },
      {
        name: 'admin-edit-vehicle',
        path: '/admin/edit-vehicle/:id',
        component: async () => await import ('../views/Admin/Vehicle/EditVehicle.vue')
      },
      {
        name: 'admin-protected-meeting',
        path: '/admin/protected-meeting',
        component: async () => await import ('../views/Admin/ProtectedMeeting/ProtectedMeeting.vue')
      },
      {
        name: 'admin-create-protected-meeting',
        path: '/admin/create-protected-meeting',
        component: async () => await import ('../views/Admin/ProtectedMeeting/CreateProtectedMeeting.vue')
      },
      {
        name: 'admin-edit-protected-meeting',
        path: '/admin/edit-protected-meeting/:id',
        component: async () => await import ('../views/Admin/ProtectedMeeting/EditProtectedMeeting.vue')
      },
      {
        name: 'admin-commission',
        path: '/admin/commission',
        component: async () => await import ('../views/Admin/Commission/Commission.vue')
      },
      {
        name: 'admin-create-commission',
        path: '/admin/create-commission',
        component: async () => await import ('../views/Admin/Commission/CreateCommission.vue')
      },
      {
        name: 'admin-edit-commission',
        path: '/admin/edit-commission/:id',
        component: async () => await import ('../views/Admin/Commission/EditCommission.vue')
      },
      {
        name: 'admin-files',
        path: '/admin/files',
        component: async () => await import ('../views/Admin/File/FileList.vue')
      },
      {
        name: 'admin-create-file',
        path: '/admin/create-file',
        component: async () => await import ('../views/Admin/File/CreateFile.vue')
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from, next) => {
  const userTokens: UserToken = await getUserTokens()

  if (userTokens?.access_token !== '') {
    // is logged
    if (to.name === 'login') {
      next({ name: 'mark-work-time' })
    }
    next()
  } else {
    // is anonymous
    if(to.name !== 'login') {
      next({ name: 'login' })
    }

    next()
  }
})

export default router
