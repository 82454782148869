import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import createAuthRefreshInterceptor from 'axios-auth-refresh'
import { Preferences } from '@capacitor/preferences'
import Vue3EasyDataTable from 'vue3-easy-data-table'
import 'vue3-easy-data-table/dist/style.css'

import { IonicVue } from '@ionic/vue'

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css'
import '@ionic/vue/css/structure.css'
import '@ionic/vue/css/typography.css'

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css'
import '@ionic/vue/css/float-elements.css'
import '@ionic/vue/css/text-alignment.css'
import '@ionic/vue/css/text-transformation.css'
import '@ionic/vue/css/flex-utils.css'
import '@ionic/vue/css/display.css'

/* Theme variables */
import './theme/variables.css'
import './theme/global.css'
import './theme/gestcoop-theme.css'

import type { UserToken } from './interfaces/UserToken'

axios.defaults.headers.post['Content-Type'] = 'application/json'
axios.defaults.baseURL = String(process.env.VUE_APP_BACKEND_BASE_URL)

let userTokens: UserToken
getUserTokens().then(async function(result) {
  await resetUnitInLocalStorage()
  userTokens = result

  if (userTokens?.access_token) {
    axios.defaults.headers.common.Authorization = 'Bearer ' + userTokens.access_token
  }

  // Add a response interceptor
  axios.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response
  }, async function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error

    // if(error.response.config.url.includes('refresh-token')) {
    // await removeUserTokens()
    // window.location.reload()
    // }

    if (error.response !== undefined) {
      if (error.response.status === 401) {
        await removeUserTokens()
        window.location.reload()
      }
      if (error.response.status === 503) {
        // Redirect to maintenance
        await router.push('/maintenance')
      }
    }

    return await Promise.reject(error)
  })

  // Function that will be called to refresh authorization
  const refreshAuthLogic = (failedRequest: any): any => axios.post('refresh-token', {},
    {
      headers: { Authorization: 'Bearer ' + userTokens.refresh_token }
    }
  ).then(async tokenRefreshResponse => {
    userTokens.access_token = tokenRefreshResponse.data.access_token
    const tokens = JSON.stringify(userTokens)
    await setInLocalStorage('user_token', tokens)

    failedRequest.response.config.headers.Authorization = 'Bearer ' + String(tokenRefreshResponse.data.access_token)
    axios.defaults.headers.common.Authorization = 'Bearer ' + String(tokenRefreshResponse.data.access_token)
  }).catch(async error => {
    console.log(error)
    await removeUserTokens()

    await router.push('/profile/login')
  })

  // Instantiate the interceptor (you can chain it as it returns the axios instance)
  createAuthRefreshInterceptor(axios, refreshAuthLogic)
}).catch(function() {})

async function setInLocalStorage(key: string, value: string): Promise<void> {
  await Preferences.set({
    key: key,
    value: value
  })
}

async function getUserTokens(): Promise<UserToken> {
  const res = await Preferences.get({ key: 'user_token' })
  let tokens: UserToken
  try {
    const value: string | null = res.value
    if (value != null) {
      tokens = JSON.parse(value)
    } else {
      tokens = {
        access_token: '',
        refresh_token: ''
      }
    }
  } catch(e) {
    console.log('getUserToken_error_catched', e)
    tokens = {
      access_token: '',
      refresh_token: ''
    }
  }
  return tokens
}

async function removeUserTokens(): Promise<void> {
  await Preferences.remove({
    key: 'user_token'
  })
}

async function resetUnitInLocalStorage(): Promise<void> {
  await Preferences.remove({
    key: 'worktime_unit_id'
  })
}

const app = createApp(App)
  .use(IonicVue)
  .use(router)

app.component('EasyDataTable', Vue3EasyDataTable)

router.isReady().then(() => {
  app.mount('#app')
}).catch((e) => {
  console.log(e)
})
