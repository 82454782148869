
import {
  IonApp,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonNote,
  IonRouterOutlet,
  IonSplitPane
} from '@ionic/vue'
import { defineComponent } from 'vue'
import {
  cardOutline,
  listOutline,
  peopleOutline,
  bookOutline,
  logOutOutline,
  shieldOutline,
  businessOutline,
  downloadOutline,
  accessibilityOutline,
  carOutline,
  clipboardOutline,
  cashOutline,
  folderOutline,
  helpCircleOutline
} from 'ionicons/icons'
import { setUserInLocalStorage, getUserTokens, getUserFromLocalStorage, removeUserTokens, removeFromLocalStorage } from '@/modules/LocalStorage'
import { userState } from '@/modules/UserState'
import { UserToken } from './interfaces/UserToken'
import axios from 'axios'
import { User } from './interfaces/User'

const appPages = [
  {
    title: 'Profilo',
    name: 'edit-user',
    iosIcon: peopleOutline,
    mdIcon: peopleOutline,
    permission: 'can-create-personal-workingTimeHistory'
  },
  {
    title: 'Timbra cartellino',
    name: 'mark-work-time',
    iosIcon: cardOutline,
    mdIcon: cardOutline,
    permission: 'can-create-personal-workingTimeHistory'
  },
  {
    title: 'Storico timbrature',
    name: 'work-time-history',
    iosIcon: listOutline,
    mdIcon: listOutline,
    permission: 'can-list-personal-workingTimeHistory'
  },
  {
    title: 'Diario',
    name: 'diary',
    iosIcon: bookOutline,
    mdIcon: bookOutline,
    permission: 'can-list-diaryRow'
  },
  {
    title: 'Scheda mezzi',
    name: 'vehicle-sheet',
    iosIcon: carOutline,
    mdIcon: carOutline,
    permission: 'can-list-personal-vehicle-sheet'
  },
  {
    title: 'Spese',
    name: 'commission',
    iosIcon: cashOutline,
    mdIcon: cashOutline,
    permission: 'can-list-personal-commission'
  },
  {
    title: 'Incontri protetti',
    name: 'protected-meeting',
    iosIcon: clipboardOutline,
    mdIcon: clipboardOutline,
    permission: 'can-list-protected-meeting'
  },
  {
    title: 'Assenze',
    name: 'request',
    iosIcon: accessibilityOutline,
    mdIcon: accessibilityOutline,
    permission: 'can-list-diaryRow'
  },
  {
    title: 'Documenti',
    name: 'files',
    iosIcon: folderOutline,
    mdIcon: folderOutline,
    permission: 'can-list-files'
  }
]

const appPagesAdmin = [
  {
    title: 'Storico timbrature',
    name: 'admin-work-time-history',
    iosIcon: listOutline,
    mdIcon: listOutline,
    permission: 'admin-can-list-workingTimeHistory'
  },
  {
    title: 'Assenze',
    name: 'admin-request',
    iosIcon: accessibilityOutline,
    mdIcon: accessibilityOutline,
    permission: 'admin-can-list-user-request'
  },
  {
    title: 'Diario',
    name: 'admin-diary',
    iosIcon: bookOutline,
    mdIcon: bookOutline,
    permission: 'admin-can-list-diaryRow'
  },
  {
    title: 'Gestione mezzi',
    name: 'admin-vehicle-sheet',
    iosIcon: carOutline,
    mdIcon: carOutline,
    permission: 'admin-can-list-vehicle-sheet'
  },
  {
    title: 'Spese',
    name: 'admin-commission',
    iosIcon: cashOutline,
    mdIcon: cashOutline,
    permission: 'admin-can-list-commission'
  },
  {
    title: 'Incontri protetti',
    name: 'admin-protected-meeting',
    iosIcon: clipboardOutline,
    mdIcon: clipboardOutline,
    permission: 'admin-can-list-protected-meeting'
  },
  {
    title: 'Utenti',
    name: 'admin-users',
    iosIcon: peopleOutline,
    mdIcon: peopleOutline,
    permission: 'admin-can-list-user'
  },
  {
    title: 'Documenti',
    name: 'admin-files',
    iosIcon: folderOutline,
    mdIcon: folderOutline,
    permission: 'admin-can-list-files'
  },
  {
    title: 'Unità',
    name: 'admin-units',
    iosIcon: businessOutline,
    mdIcon: businessOutline,
    permission: 'admin-can-list-unit'
  },
  {
    title: 'Cooperativa',
    name: 'admin-edit-coop',
    iosIcon: businessOutline,
    mdIcon: businessOutline,
    permission: 'admin-can-view-coop'
  },
  {
    title: 'Ruoli e permessi',
    name: 'admin-roles',
    iosIcon: shieldOutline,
    mdIcon: shieldOutline,
    permission: 'admin-can-list-role'
  },
  {
    title: 'Esportazioni',
    name: 'admin-export',
    iosIcon: downloadOutline,
    mdIcon: downloadOutline,
    permission: 'admin-can-export'
  }
]

export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonContent,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonMenu,
    IonMenuToggle,
    IonNote,
    IonRouterOutlet,
    IonSplitPane
  },
  setup() {
    return {
      cardOutline,
      listOutline,
      peopleOutline,
      bookOutline,
      logOutOutline,
      shieldOutline,
      businessOutline,
      downloadOutline,
      accessibilityOutline,
      folderOutline,
      helpCircleOutline
    }
  },
  data() {
    return {
      pages: appPages,
      adminPages: appPagesAdmin,
      selectedIndex: undefined as unknown,
      adminSelectedIndex: undefined as unknown,
      userState
    }
  },
  computed: {
    isLogin() {
      const path = this.$route.path
      return path === '/login'
    },
    isAdmin() {
      const permissions = this.userState.user.permissions
      for (let i = 0; i < permissions.length; i++) {
        if (permissions[i].search(/admin/gi) !== -1) return true
      }
      return false
    }
  },
  watch: {
    async '$route'() {
      const user: User | undefined = await getUserFromLocalStorage()
      if (user !== undefined) {
        userState.user.coop_name = user.coop_name
        userState.user.coop_id = user.coop_id
        userState.user.first_name = user.first_name
        userState.user.last_name = user.last_name
        userState.user.email = user.email
        userState.user.roles = user.roles
        userState.user.permissions = user.permissions
        userState.user.worker_id = user.worker_id
        userState.user.id = user.id
        userState.user.settings = user.settings
      }
    }
  },
  async mounted() {
    const userTokens: UserToken = await getUserTokens()

    if (userTokens?.access_token !== '') this.getUser()
  },
  methods: {
    goToPage(index: number, pageName: string, type: string) {
      if (type === 'employee') {
        this.selectedIndex = index
        this.adminSelectedIndex = undefined
      }
      if (type === 'admin') {
        this.adminSelectedIndex = index
        this.selectedIndex = undefined
      }
      this.$router.push({ name: pageName })
    },
    getUser() {
      axios
        .get('/user')
        .then((response) => {
          if (response.data.success) {
            const user: User = response.data.user_data
            setUserInLocalStorage(user)
            userState.user = user
          } else {
            alert(response.data.message)
          }
        })
        .catch((error) => {
          console.log('ERROR_login', error)
        })
    },
    logout() {
      removeUserTokens()
      removeFromLocalStorage('user')
      window.location.reload()
    },
    hasPermission(permission: string) {
      return this.userState.user.permissions.includes(permission)
    }
  }
})
