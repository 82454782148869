
import { defineComponent } from 'vue'
import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar } from '@ionic/vue'
import { RouteRecordName, RouterView } from 'vue-router'
import FooterGecoop from '@/components/Footer.vue'

interface HashMap {
  [index: string]: string;
}

export default defineComponent({
  name: 'BaseView',
  components: { IonHeader, IonToolbar, IonTitle, IonContent, IonPage, IonButtons, IonMenuButton, RouterView, FooterGecoop },
  computed: {
    titlePage() {
      const routeName: RouteRecordName | null | undefined = this.$route.name
      let title: string = ''

      const routeNames: HashMap = {
        'mark-work-time': 'Timbra cartellino',
        'work-time-history': 'Storico timbrature',
        'create-work-time-history': 'Nuova timbratura',
        // eslint-disable-next-line quote-props
        'diary': 'Diario',
        'edit-diary': 'Modifica diario',
        // eslint-disable-next-line quote-props
        'request': 'Assenze',
        'create-request': 'Gestisci assenza',
        'edit-request': 'Modifica assenza',
        'vehicle-sheet': 'Scheda mezzi',
        'create-vehicle-sheet': 'Gestisci scheda mezzo',
        'edit-vehicle-sheet': 'Modifica scheda mezzo',
        'protected-meeting': 'Lista incontri protetti',
        'view-protected-meeting': 'Visualizza incontro',
        'create-protected-meeting': 'Nuovo incontro',
        'edit-protected-meeting': 'Modifica incontro',
        // eslint-disable-next-line quote-props
        'files': 'Documenti',
        // eslint-disable-next-line quote-props
        'commission': 'Lista spese',
        'create-commission': 'Nuova spesa',
        'edit-commission': 'Modifica spesa',
        'admin-work-time-history': 'Storico timbrature',
        'admin-create-work-time-history': 'Nuova timbratura',
        'admin-users': 'Utenze',
        'admin-create-user': 'Crea utenza',
        'admin-edit-user': 'Modifica utenza',
        'admin-roles': 'Ruoli',
        'admin-create-role': 'Crea ruolo',
        'admin-edit-role': 'Modifica ruolo',
        'admin-units': 'Lista unità',
        'admin-create-unit': 'Crea unità',
        'admin-edit-unit': 'Modifica unità',
        'admin-create-subunit': 'Crea sottounità',
        'admin-edit-subunit': 'Modifica sottounità',
        'admin-diary': 'Diario',
        'admin-edit-diary': 'Modifica diario',
        'admin-request': 'Assenze',
        'admin-create-request': 'Gestisci assenza',
        'admin-edit-request': 'Modifica assenza',
        'admin-export': 'Esportazioni',
        'admin-vehicle-sheet': 'Gestione mezzi',
        'admin-create-vehicle-sheet': 'Gestisci scheda mezzo',
        'admin-edit-vehicle-sheet': 'Modifica scheda mezzo',
        'admin-vehicle': 'Lista mezzi',
        'admin-create-vehicle': 'Gestisci mezzo',
        'admin-edit-vehicle': 'Modifica mezzo',
        'admin-protected-meeting': 'Lista incontri protetti',
        'admin-create-protected-meeting': 'Nuovo incontro',
        'admin-edit-protected-meeting': 'Modifica incontro',
        'admin-commission': 'Lista spese',
        'admin-create-commission': 'Nuova spesa',
        'admin-edit-commission': 'Modifica spesa',
        'admin-edit-coop': 'Cooperativa',
        'admin-files': 'Documenti cooperativa',
        'admin-create-file': 'Carica documenti'
      }

      if (!routeName) return 'Pagina'

      title = routeNames[routeName.toString()]
      return title
    }
  }
})
