
import { defineComponent } from 'vue'
import { IonFooter, IonToolbar, IonNote } from '@ionic/vue'

export default defineComponent({
  // eslint-disable-next-line vue/multi-word-component-names, vue/no-reserved-component-names
  name: 'FooterGecoop',
  components: {
    IonFooter, IonToolbar, IonNote
  }
})
