import type { Role } from '@/interfaces/Role'
import type { User } from '@/interfaces/User'
import { reactive } from 'vue'

export const userState = reactive({
  user: {
    id: 0,
    first_name: '' as string | undefined,
    last_name: '' as string | undefined,
    worker_id: '',
    roles: [] as string[] | Role[],
    email: '' as string | undefined,
    coop_name: '',
    coop_id: 0,
    permissions: [] as string[],
    phone: '' as string | undefined,
    address: '' as string | undefined,
    gender: '' as string | undefined,
    title: '' as string | undefined,
    settings: undefined as any,
    supervisor: undefined as User | undefined,
    subordinates: undefined as User[] | undefined,
    color_in_diary: '' as string | undefined
  } satisfies User
})
