import { type GetResult, Preferences } from '@capacitor/preferences'
import type { UserToken } from '@/interfaces/UserToken'
import type { User } from '@/interfaces/User'

export async function setInLocalStorage(key: string, value: string): Promise<void> {
  await Preferences.set({
    key: key,
    value: value
  })
}

export async function getFromLocalStorage(key: string): Promise<GetResult> {
  const res: GetResult = await Preferences.get({ key: key })
  return res
}

export async function removeFromLocalStorage(key: string): Promise<void> {
  await Preferences.remove({
    key: key
  })
}

export async function removeUserTokens(): Promise<void> {
  await Preferences.remove({
    key: 'user_token'
  })
}

export async function getUserTokens(): Promise<UserToken> {
  const res = await Preferences.get({ key: 'user_token' })
  let tokens: UserToken
  try {
    const value: string | null = res.value
    if (value != null) {
      tokens = JSON.parse(value)
    } else {
      tokens = {
        access_token: '',
        refresh_token: ''
      }
    }
  } catch(e) {
    console.log('getUserToken_error_catched', e)
    tokens = {
      access_token: '',
      refresh_token: ''
    }
  }
  return tokens
}

export async function setUserInLocalStorage(user: User): Promise<void> {
  const userJson = JSON.stringify(user)
  await setInLocalStorage('user', userJson)
}

export async function getUserFromLocalStorage(): Promise<User | undefined> {
  const res: GetResult = await getFromLocalStorage('user')
  let user: User | undefined
  try {
    const value: string | null = res.value
    if (value != null) {
      user = JSON.parse(value)
    }
  } catch(e) {
    console.log('getUserToken_error_catched', e)
  }
  return user
}
